exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agro-4-g-5-g-js": () => import("./../../../src/pages/agro-4g5g.js" /* webpackChunkName: "component---src-pages-agro-4-g-5-g-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-data-center-case-js": () => import("./../../../src/pages/data-center-case.js" /* webpackChunkName: "component---src-pages-data-center-case-js" */),
  "component---src-pages-data-center-js": () => import("./../../../src/pages/data-center.js" /* webpackChunkName: "component---src-pages-data-center-js" */),
  "component---src-pages-educacao-js": () => import("./../../../src/pages/educação.js" /* webpackChunkName: "component---src-pages-educacao-js" */),
  "component---src-pages-hotel-hospitalar-js": () => import("./../../../src/pages/hotel-hospitalar.js" /* webpackChunkName: "component---src-pages-hotel-hospitalar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industria-alimenticia-js": () => import("./../../../src/pages/industria-alimenticia.js" /* webpackChunkName: "component---src-pages-industria-alimenticia-js" */),
  "component---src-pages-industria-automotiva-js": () => import("./../../../src/pages/industria-automotiva.js" /* webpackChunkName: "component---src-pages-industria-automotiva-js" */),
  "component---src-pages-industria-farmaceutica-js": () => import("./../../../src/pages/industria-farmaceutica.js" /* webpackChunkName: "component---src-pages-industria-farmaceutica-js" */),
  "component---src-pages-industria-quimica-js": () => import("./../../../src/pages/industria-quimica.js" /* webpackChunkName: "component---src-pages-industria-quimica-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-papel-celulose-js": () => import("./../../../src/pages/papel-celulose.js" /* webpackChunkName: "component---src-pages-papel-celulose-js" */),
  "component---src-pages-perguntas-js": () => import("./../../../src/pages/perguntas.js" /* webpackChunkName: "component---src-pages-perguntas-js" */),
  "component---src-pages-politica-privacidade-js": () => import("./../../../src/pages/politica-privacidade.js" /* webpackChunkName: "component---src-pages-politica-privacidade-js" */),
  "component---src-pages-politica-qualidade-js": () => import("./../../../src/pages/politica-qualidade.js" /* webpackChunkName: "component---src-pages-politica-qualidade-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sistemas-eletronicos-js": () => import("./../../../src/pages/sistemas-eletronicos.js" /* webpackChunkName: "component---src-pages-sistemas-eletronicos-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-tecnologia-informacao-js": () => import("./../../../src/pages/tecnologia-informacao.js" /* webpackChunkName: "component---src-pages-tecnologia-informacao-js" */),
  "component---src-pages-telecom-js": () => import("./../../../src/pages/telecom.js" /* webpackChunkName: "component---src-pages-telecom-js" */),
  "component---src-pages-trabalhe-conosco-js": () => import("./../../../src/pages/trabalhe-conosco.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-js" */)
}

